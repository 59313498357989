import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  /*
  obtener() {
    var url = ConfigAPI.baseURL + "widget" + Session.getToken();
    return instance.get(url);
  },
  mostrar(id) {
    var url = ConfigAPI.baseURL + "widget/" + id + Session.getToken();
    return instance.get(url);
  },
  */
  mostrarByReferencia(data) {
    var param = data

    var url = ConfigAPI.baseURL + "widget/reference" + Session.getToken();
    return instance.post(url,param);
  },
  mostrarByReferenciaCustom(data) {
    var param = data

    var url = ConfigAPI.baseURL + "php/widget/reference" + Session.getToken();
    return instance.post(url,param);
  },  
  obtenerEmpleado() {
    var url = ConfigAPI.baseURL + "employee/employee" + Session.getToken();
    return instance.get(url);
  },    
}

export default servicesAPI;
