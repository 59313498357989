<template>
  <div>
    <b-embed
      type="iframe"      
      :src="this.data"
      allowfullscreen
    ></b-embed>        
  </div>  
</template>

<script>
  import {EventBus} from '@/handler/event-bus'

  export default {
    props: {
      data: {
        type: String,
        required: true
      },      
    },
  }
</script>
