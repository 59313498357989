<template>
  <div v-if="!showFullWidget" class="indicator-text" v-bind:style="{ color: colorText}">
    {{this.data.value}}
  </div>
  <div v-else>  
    <i :class="classIconFullWidget"></i>
    <div :class="classValueFullWidget">{{this.data.value}}</div>
    <div class="text-muted text-uppercase font-weight-bold font-xs">{{this.data.title}}</div>    
  </div>  
</template>

<script>
  import {EventBus} from '@/handler/event-bus'

  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
      colorText: {
        type: String,
        default: 'green'
      }
    },
    data(){
      return {
        showFullWidget: false,
        classIconFullWidget: 'fa p-4 px-5 font-2xl mr-3 float-left',
        classValueFullWidget: 'h5 mb-0 pt-3'
      }
    },
    mounted() {      
      this.configWidget()
      this.setVariant()
      this.setIcon()
      this.setColor()
    },
    methods: {
      configWidget(){
        if(this.data.type == 'basic') {
          this.showFullWidget = false 
        } 
        else {
          this.showFullWidget = true 
        }    
      },
      setVariant() {
        if(this.data.variant) {
          this.classIconFullWidget = this.classIconFullWidget + ' bg-' + this.data.variant
          this.classIconFullWidget = this.classIconFullWidget + ' bg-' + this.data.variant
          this.classValueFullWidget = this.classValueFullWidget + ' text-' + this.data.variant
        }      
      },
      setIcon() {
        if(this.data.icon) {
          this.classIconFullWidget = this.classIconFullWidget + ' ' + this.data.icon
        }      
      },
      setColor() {
        if(this.data.color) {
          this.colorText = this.data.color
        }      
      }
    }
  }
</script>

<style>
  .indicator-text {
    font-size: 37px;
    font-weight: 700;
    text-align: center;
    color: green;
  }
</style>
