<template>
  <div class="mb-0 table-responsive widget-list-custom">    
    <table class="table b-table table-hover table" v-if="table.showTable">
      <thead class="thead-light">
        <tr>
          <th v-for="(value, index) in table.fields" :key="index">
            <div v-html="value"></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value1, index1) in table.items" :key="index1">          
          <td v-for="(value2, elemento2, index2) in value1" :key="index2" class="align-middle">            
            <div v-if="elemento2 != table.router.fieldValue" v-html="value2"></div>
            <div v-else class="text-center">
              <b-link @click="irDetalle(value2)">
                  <small class="text-center">
                    <b-icon icon="chevron-double-right"></b-icon>
                  </small>
              </b-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>    
    <b-alert v-else variant="warning" show class="m-3">
      {{this.data.items}}
    </b-alert>  
  </div>
</template>

<script>
  import {EventBus} from '@/handler/event-bus'
  import Session from '@/handler/session'  

  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
    },
    data(){
      return {  
        primaryColor: '',      
        table: {
          items: [],
          fields: [],        
          router: [],
          showTable: false,
        },       
      }
    },
    mounted() {      
      this.setup()
      this.configWidget()      
    },
    watch: {
      data: function(){
        this.configWidget()
      }
    },
    methods: {
      configWidget(){        
        if(typeof this.data.items == 'string') {
          this.table.showTable = false        
        } else {
          this.table.showTable = true          
        } 

        this.table.fields = this.data.fields
        this.table.items = this.data.items   
        this.table.router = this.data.router       
      },  
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary                    
          this.createCSS()
        }
      },      
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""              
        hoja.innerHTML = hoja.innerHTML  + ".b-pagination .page-item.active .page-link { background-color: " + this.primaryColor + "; border-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      irDetalle(valueParam) {               
        var jsonParams= []
        jsonParams[this.table.router.param] = valueParam
        this.$router.push({ name: this.table.router.name, params: jsonParams})        
      }          
    }
  }
</script>

<style>
  .widget-list-custom {
    overflow: auto;
    max-height: 300px;        
  }  
</style>
